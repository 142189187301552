import { Theme } from './index'
import donatiLogo from './logos/donati.svg'
import donatiCleverLogo from './logos/donati-clever.svg'
import whiteDonatiLogo from './logos/donati-white.svg'
import bannerDesktop from './images/donati-banner-desktop.jpg'
import bannerTablet from './images/donati-banner-tablet.jpg'
import bannerMobile from './images/donati-banner-mobile.jpg'
import { getBrandedModule } from './defaultModules'
import DocumentationMenu from '../components/DocumentationMenu'



const colors = {
  red: '#E42E33',
  white: '#FFFFFF',
  black: '#000000',
  button: {
    color: '#E42E33',
    hover: '#8B1314',
    disabled: '#DDDDDD',
  },
  text: {
    body: '#323231',
    headings: '#323231',
    link: '#E42E33',
    linkHover: '#8B1314',
  },
  monochrome: {
    darkestGrey: '#282B2E',
    darkerGrey: '#5B5B5B',
    darkGrey: '#777777',
    mediumGrey: '#AAAAAA',
    lightGrey: '#DDDDDD',
    lighterGrey: '#F2F2F2',
    lightestGrey: '#F8F8F8',
  },
}

const smallIcon = {
  style: {
    fontSize: '3em',
  },
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const primaryTile = {
  color: colors.black,
  backgroundColor: colors.monochrome.lightestGrey,
  hoverBackgroundColor: colors.monochrome.lightGrey,
}

const demoTile = {
  color: colors.monochrome.darkestGrey,
  backgroundColor: colors.monochrome.lightestGrey,
  // iconColor: colors.monochrome.darkestGrey,
}

const externalTile = {
  color: colors.white,
  backgroundColor: colors.red,
  hoverBackgroundColor: colors.button.hover,
}

const themeConfig: Theme = {
  brand: 'donati',
  privacyPolicyLink: 'https://donaticranes.com/en/privacy-policy',
  cookiePolicyLink: 'https://donaticranes.com/en/privacy-policy',
  companyLink: 'https://donaticranes.com',
  siteTermsLink: '/terms-and-conditions/alpha-portal/latest.default.html',
  fontFamily: 'Frutiger',
  fontSize: '15px',
  headerLogo: donatiCleverLogo,
  altHeaderLogo: donatiLogo,
  menuLogo: donatiLogo,
  footerLogo: whiteDonatiLogo,
  headerLogoSize: '300px',
  altHeaderLogoSize: '200px',
  menuLogoSize: '200px',
  footerLogoSize: '150px',
  footerCopyrightId: 'donati_copyright',
  footerBackgroundColor: colors.black,
  primary: colors.red,
  pageHeaderColor: colors.text.headings,
  headerBackgroundColor: colors.monochrome.darkestGrey,
  linkColor: colors.text.link,
  linkHoverColor: colors.text.linkHover,
  dashboardBanner: {
    desktopImage: bannerDesktop,
    tabletImage: bannerTablet,
    mobileImage: bannerMobile,
    borderColor: colors.monochrome.lightestGrey,
  },
  header: {
    color: colors.black,
    backgroundColor: colors.monochrome.darkestGrey,
  },
  menuLink: {
    color: colors.black,
    hoverBackgroundColor: colors.monochrome.lightestGrey,
    hoverColor: colors.red,
  },
  listItem: {
    titleColor: colors.black,
    descriptionColor: colors.black,
  },
  button: {
    primary: {
      color: colors.button.color,
      textColor: colors.white,
      hover: colors.button.hover,
    },
    secondary: {
      color: colors.monochrome.mediumGrey,
    },
  },
  modules: [
    // Main modules below:
    getBrandedModule('dashboard'),
    getBrandedModule('eLearning', {
      tileProps: externalTile,
      tileIconProps: {
        width: '78px',
      },
    }),
    getBrandedModule('businessDashboard', {
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '5em',
        },
      },
    }),
    getBrandedModule('contact'),
    getBrandedModule('orderDocumentation', {
      tileProps: externalTile,
      tileIconProps: {
        style: {
          fontSize: '5em',
        },
      },
    }),

    getBrandedModule('store', {
      to: 'https://shop.donaticranes.com/don/it/login',
      title: 'donati_shop',
      secondary: true,
      tileProps: externalTile,
    }),

    getBrandedModule('leonardoProductConfigurator', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('leonardoCraneSetConfigurator', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('electricChainHoists', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('electricWireRopeHoists', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('jibCranes', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('driveUnits', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('endCarriages', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('channelProfiles', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('craneSets', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('suspendedMonogirderBridgeCranes', {
      secondary: true,
      tileProps: externalTile,
    }),
    getBrandedModule('documents', {
      to: '/never', // So that it doesn't show as active in the side menu.
      title: 'information_documents',
      secondary: true,
      description: 'documentation_description',
      external: false,
      tileProps: externalTile,
      modalContent: <DocumentationMenu />,
    }),
    // Demo modules below:
    getBrandedModule('trainingCalendar', {
      demo: true,
      secondary: true,
      tileProps: demoTile,
      tileIconProps: smallIcon
    }),
    getBrandedModule('leadTimes', {
      demo: true,
      secondary: true,
      tileProps: demoTile,
      tileIconProps: smallIcon
    }),
    getBrandedModule('marketingBank', {
      demo: true,
      secondary: true,
      tileProps: demoTile,
      tileIconProps: smallIcon
    }),
  ],
}

export function loadTheme(): void {
  setFontSize()
  loadIcon()
}

function loadIcon() {
  const head = document.getElementsByTagName('head')[0]
  const linkEl = document.createElement('link')

  linkEl.rel = 'icon'
  linkEl.type = 'image/x-icon'
  linkEl.href = './icons/donati.ico'
  head.insertBefore(linkEl, head.firstChild)
}

function setFontSize() {
  document.documentElement.style.fontSize = themeConfig.fontSize!
}

export default themeConfig
