import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import {
  faHome,
  faEnvelope,
  faWrench,
  faCogs,
  faCopy,
  faGlobe,
  faShoppingCart,
  faBullhorn,
  faWifi,
  faHistory,
  faAnalytics,
  faCalendarAlt,
  faMobileAlt,
  faFileAlt,
  faPhotoVideo
} from '@fortawesome/pro-solid-svg-icons'

import FEATURES from '../constants/features'
import NewEquipmentMenu from '../components/NewEquipmentMenu'
import PriceList from '../components/PriceList'
import TrainingIcon from '../icons/TrainingIcon'
import TrackerIcon from '../icons/TrackerIcon'
import ChainSystemIcon from '../icons/ChainSystemIcon'
import WarrantyIcon from '../icons/WarrantyIcon'
import PriceListIcon from '../icons/PriceListIcon'
import NewsIcon from '../icons/NewsIcon'
import { Module, ModuleName } from './index'

const customIconSize = '43px'

export const dashboard: Module = {
  name: 'dashboard',
  to: '/',
  title: 'dashboard',
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faHome,
    size: 'lg',
  },
}

export const contact: Module = {
  name: 'contact',
  to: '/contact-us',
  title: 'contact_info',
  menuItemTitle: 'contact',
  showInMenu: true,
  permissionCheckArgs: [FEATURES.CONTACT_INFO, ['R']],
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faEnvelope,
    size: 'lg',
  },
}

export const documents: Module = {
  name: 'documents',
  to: '/',
  title: 'documents',
  description: 'documents_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.STORE_ACCESS, ['R']],
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faFileAlt,
    size: 'lg',
  },
  tileIconProps: {
    icon: faFileAlt,
    size: '3x',
  },
}

export const documentsAndManuals: Module = {
  name: 'documentsAndManuals',
  to: '/documents-and-manuals',
  title: 'documents_and_manuals',
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.DOCUMENTS_AND_MANUALS, ['R']],
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    size: '5x',
  },
}

export const myPartnersAndEquipment: Module = {
  name: 'myPartnersAndEquipment',
  to: '/my-partners-and-equipment',
  title: 'my_partners_and_equipment',
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.ASSET_FLEET, ['R']],
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCogs,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCogs,
    size: '5x',
  },
}

export const troubleshooting: Module = {
  name: 'troubleshooting',
  to: '/troubleshooting',
  title: 'troubleshooting',
  description: 'technical_self_help',
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.TROUBLESHOOTING, ['R']],
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faWrench,
    size: 'lg',
  },
  tileIconProps: {
    icon: faWrench,
    size: '3x',
  },
}

export const eLearning: Module = {
  name: 'eLearning',
  to: '/elearning',
  title: 'elearning',
  description: 'elearning_description',
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.COURSES, ['R']],
  IconComponent: TrainingIcon,
  tileIconProps: {
    width: '48px',
  },
}

export const serviceDocuments: Module = {
  name: 'serviceDocuments',
  to: '/service-documents',
  title: 'service_documents',
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.SERVICE_DOCUMENTS, ['R']],
  IconComponent: Icon,
  tileIconProps: {
    size: '3x',
    icon: faFileAlt,
  },
  menuItemIconProps: {
    icon: faFileAlt,
    size: 'lg',
  },
}

export const healthApp: Module = {
  name: 'healthApp',
  to: '/health-app',
  title: 'health_app',
  description: 'mobile_app',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faMobileAlt,
    size: 'lg',
  },
  tileIconProps: {
    icon: faMobileAlt,
    size: '3x',
  },
}

export const termsOfSale: Module = {
  name: 'termsOfSale',
  to: '/terms-of-sale',
  title: 'terms_of_sale',
  IconComponent: Icon,
  description: 'terms_of_sale_description',
}

export const store: Module = {
  name: 'store',
  to: '/store',
  title: 'store',
  description: 'store_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faShoppingCart,
    size: 'lg',
  },
  tileIconProps: {
    icon: faShoppingCart,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const orderDocumentation: Module = {
  name: 'orderDocumentation',
  to: 'https://shop.donaticranes.com/don/en/documents',
  title: 'order_documentation',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}


export const deliveryTracker: Module = {
  name: 'deliveryTracker',
  to: '/',
  title: 'delivery_tracker',
  description: 'delivery_tracker_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.STORE_ACCESS, ['R']],
  IconComponent: TrackerIcon,
  tileIconProps: {
    width: '50px',
  },
}


export const mediaLibrary: Module = {
  name: 'mediaLibrary',
  to: '/',
  title: 'media_library',
  description: 'media_library_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  tileIconProps: {
    icon: faPhotoVideo,
    size: '3x',
  },
}


export const statusControl: Module = {
  name: 'statusControl',
  to: '/',
  title: 'status_control',
  description: 'Additional information', // FIXME: add to translations?
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faWifi,
    size: 'lg',
  },
  tileIconProps: {
    icon: faWifi,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const chainSystem: Module = {
  name: 'chainSystem',
  to: '/chain-system',
  title: 'chain_system',
  description: 'chain_system_description',
  showExternalIcon: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: ChainSystemIcon,
  tileIconProps: {
    width: '50px',
  },
}

// SWF only
export const craneMaster: Module = {
  name: 'craneMaster',
  to: '/crane-master',
  title: 'crane_master',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: ChainSystemIcon,
  tileIconProps: {
    width: '50px',
  },
}

// Verlinde only
export const generalPriceList: Module = {
  name: 'generalPriceList',
  to: '/pricelists/Verlinde-2024-Tarif-rev-July-2024.pdf',
  title: 'general_price_list',
  external: true,
  showOnDashboard: false,
  showInMenu: false,
  IconComponent: PriceListIcon,
  tileIconProps: {
    width: '50px',
  },
}

export const stagemakerPriceList: Module = {
  name: 'stagemakerPriceList',
  to: '/pricelists/Stagemaker-2024-Tarif-rev-July-2024.pdf',
  title: 'stagemaker_price_list',
  external: true,
  showOnDashboard: false,
  showInMenu: false,
  IconComponent: PriceListIcon,
  tileIconProps: {
    width: '50px',
  },
}

export const leadTimes: Module = {
  name: 'leadTimes',
  to: '/lead-times',
  title: 'lead_times',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faHistory,
    size: 'lg',
  },
  tileIconProps: {
    icon: faHistory,
    style: {
      fontSize: '5em',
    },
  },
}

export const leadTimeDemo: Module = {
  name: 'leadTimeDemo',
  to: '/lead-time-demo',
  title: 'lead_times',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faHistory,
    size: 'lg',
  },
  tileIconProps: {
    icon: faHistory,
    style: {
      fontSize: '5em',
    },
  },
}

export const businessDashboard: Module = {
  name: 'businessDashboard',
  to: '/business-dashboard',
  title: 'business_dashboard',
  description: 'business_dashboard_description',
  showOnDashboard: true,
  showInMenu: true,
  permissionCheckArgs: [FEATURES.BUSINESS_DASHBOARD, ['R']],
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faAnalytics,
    size: 'lg',
  },
  tileIconProps: {
    icon: faAnalytics,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const marketingBank: Module = {
  name: 'marketingBank',
  to: '/marketing-bank',
  title: 'marketing_bank',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faBullhorn,
    size: 'lg',
  },
  tileIconProps: {
    icon: faBullhorn,
    style: {
      fontSize: '80px',
    },
  },
}

export const trainingCalendar: Module = {
  name: 'trainingCalendar',
  to: '/training-calendar',
  title: 'training_calendar',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCalendarAlt,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCalendarAlt,
    style: {
      fontSize: '78px',
    },
  },
}

export const remoteDataDemo: Module = {
  name: 'remoteDataDemo',
  to: '/remote-data',
  title: 'remote_data',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faWifi,
    size: 'lg',
  },
  tileIconProps: {
    icon: faWifi,
    style: {
      fontSize: '78px',
    },
  },
}

export const monitoredAsset: Module = {
  name: 'monitoredAsset',
  to: '/monitored-asset',
  title: 'remote_data',
  IconComponent: Icon,
  showInMenu: false,
  tileIconProps: {
    icon: faWifi,
    style: {
      fontSize: '78px',
    },
  },
}

export const warranty: Module = {
  name: 'warranty',
  to: '/warranty',
  title: 'warranty',
  description: 'place_warranty_notifications',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: WarrantyIcon,
  tileIconProps: {
    width: '45px',
  },
  menuItemIconProps: {
    width: '24px',
  },
}

export const verlindeCom: Module = {
  name: 'verlindeCom',
  to: 'https://www.verlinde.com',
  title: 'verlinde_com',
  description: 'verlinde_com_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faGlobe,
    size: 'lg',
  },
  tileIconProps: {
    icon: faGlobe,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const training: Module = {
  name: 'training',
  to: '',
  title: 'training',
  description: 'training_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCalendarAlt,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCalendarAlt,
    size: '3x',
  },
}

// Verlinde specific
export const priceList: Module = {
  name: 'priceList',
  to: '/',
  title: 'price_list',
  description: 'price_list_description',
  showOnDashboard: true,
  IconComponent: PriceListIcon,
  tileIconProps: {
    width: '78px',
  },
  modalContent: <PriceList />,
}

export const mySWF: Module = {
  name: 'mySWF',
  to: 'https://www.swfkrantechnik.com/myswf/',
  title: 'my_swf',
  description: 'my_swf_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faGlobe,
    size: 'lg',
  },
  tileIconProps: {
    icon: faGlobe,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const modalMenu: Module = {
  name: 'modalMenu',
  to: '/',
  title: 'new_equipment_configurators_and_spare_parts',
  description: 'chain_system_description',
  showOnDashboard: true,
  IconComponent: ChainSystemIcon,
  tileIconProps: {
    width: '78px',
  },
  modalContent: <NewEquipmentMenu />,
}

export const news: Module = {
  name: 'news',
  to: '/news',
  title: 'verlinde_news',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: NewsIcon,
  tileIconProps: {
    width: '40px',
  },
  menuItemIconProps: {
    width: '24px',
  },
}

export const stagemaker: Module = {
  name: 'stagemaker',
  to: 'https://www.stagemaker.com/',
  title: 'stagemaker',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faGlobe,
    size: 'lg',
  },
  tileIconProps: {
    icon: faGlobe,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const leonardoProductConfigurator: Module = {
  name: 'leonardoProductConfigurator',
  to: 'https://leonardoproductconfigurator.donaticranes.com/donati/login',
  title: 'leonardo_product_configurator',
  description: 'leonardo_product_configurator_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: ChainSystemIcon,
  tileIconProps: {
    width: '50px',
  },
}

export const leonardoCraneSetConfigurator: Module = {
  name: 'leonardoCraneSetConfigurator',
  to: 'https://kc.allonweb.eu/?signin',
  title: 'leonardo_crane_set_configurator',
  description: 'leonardo_crane_set_configurator_description',
  external: true,
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: ChainSystemIcon,
  tileIconProps: {
    width: '50px',
  },
}

export const electricChainHoists: Module = {
  name: 'electricChainHoists',
  to: 'https://donaticranes.com/en/documentation/brochures-and-user-guides-electric-chain-hoist',
  title: 'electric_chain_hoists',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const electricWireRopeHoists: Module = {
  name: 'electricWireRopeHoists',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-electric-wire-rope-hoist',
  title: 'electric_wire_rope_hoists',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const jibCranes: Module = {
  name: 'jibCranes',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-manual-and-electric-jib-cranes',
  title: 'jib_cranes',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const driveUnits: Module = {
  name: 'driveUnits',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-drive-unit-cranes',
  title: 'drive_units',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const endCarriages: Module = {
  name: 'endCarriages',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-endcarriages-bridge-cranes',
  title: 'end_carriages',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const channelProfiles: Module = {
  name: 'channelProfiles',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-channel-profile',
  title: 'channel_profiles',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const craneSets: Module = {
  name: 'craneSets',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-crane-set',
  title: 'crane_sets',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const suspendedMonogirderBridgeCranes: Module = {
  name: 'suspendedMonogirderBridgeCranes',
  to: 'https://donaticranes.com/en/documentation/sales-brochure-and-user-guide-kit-suspended-monogirder-bridge-crane',
  title: 'suspended_monogirder_bridge_cranes',
  external: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faCopy,
    size: 'lg',
  },
  tileIconProps: {
    icon: faCopy,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const assetFleet: Module = {
  name: 'assetFleet',
  to: '/asset-fleet',
  title: 'remote_data',
  showOnDashboard: true,
  showInMenu: true,
  IconComponent: Icon,
  menuItemIconProps: {
    icon: faWifi,
    size: 'lg',
  },
  tileIconProps: {
    icon: faWifi,
    style: {
      fontSize: customIconSize,
    },
  },
}

export const hotjarDecrypt: Module = {
  name: 'hotjarDecrypt',
  to: '/hj',
  title: 'hj',
  description: 'decrypt',
  showOnDashboard: false,
  showInMenu: false,
  permissionCheckArgs: [FEATURES.HOTJAR, ['R']],
  IconComponent: TrainingIcon,
  tileIconProps: {
    width: '48px',
  },
}

export const activationFlow: Module = {
  name: 'activationFlow',
  to: '/activation-flow',
  title: 'Activation flow',
  permissionCheckArgs: [FEATURES.REMOTE_MONITORING, ['C']],
  showOnDashboard: false,
  showInMenu: false,
  IconComponent: TrainingIcon,
  tileIconProps: {
    width: '48px',
  },
}

const defaultModules: Record<ModuleName, Module> = {
  dashboard,
  contact,
  documents,
  documentsAndManuals,
  myPartnersAndEquipment,
  troubleshooting,
  eLearning,
  serviceDocuments,
  healthApp,
  store,
  deliveryTracker,
  mediaLibrary,
  statusControl,
  chainSystem,
  craneMaster,
  leadTimes,
  leadTimeDemo,
  businessDashboard,
  marketingBank,
  trainingCalendar,
  remoteDataDemo,
  warranty,
  verlindeCom,
  training,
  priceList,
  generalPriceList,
  stagemakerPriceList,
  mySWF,
  modalMenu,
  news,
  stagemaker,
  leonardoProductConfigurator,
  leonardoCraneSetConfigurator,
  electricChainHoists,
  electricWireRopeHoists,
  jibCranes,
  driveUnits,
  endCarriages,
  channelProfiles,
  craneSets,
  suspendedMonogirderBridgeCranes,
  assetFleet,
  hotjarDecrypt,
  activationFlow,
  termsOfSale,
  orderDocumentation,
  monitoredAsset
}

type ModuleProps = Partial<Omit<Module, 'name'>>

export function getBrandedModule(name: ModuleName, props?: ModuleProps) {
  const defaultModule = defaultModules[name]
  const brandedModule: Module = {
    ...defaultModule,
    ...props,
    tileProps: {
      ...defaultModule.tileProps,
      ...props?.tileProps,
    },
    menuItemIconProps: {
      ...defaultModule.menuItemIconProps,
      ...props?.menuItemIconProps,
    },
    tileIconProps: {
      ...defaultModule.tileIconProps,
      ...props?.tileIconProps,
    },
  }

  return brandedModule
}

export default defaultModules
