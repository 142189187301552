// this file based on https://react.i18next.com/guides/quick-start
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpBackend from 'i18next-http-backend'

import LocalBackend from './LocalBackend'
import english from '../translations/English.json'
import german from '../translations/German.json'
import italian from '../translations/Italian.json'
import spanish from '../translations/Spanish.json'
import french from '../translations/French.json'
import chinese from '../translations/Chinese.json'

type LanguageSelection =
  | 'language_select_english'
  | 'language_select_german'
  | 'language_select_spanish'
  | 'language_select_french'
  | 'language_select_italian'
  | 'language_select_chinese'

export type Language = {
  lang: string
  langSelect: LanguageSelection
  langCode: string
}

export const languages: Language[] = [
  {
    lang: 'English',
    langSelect: 'language_select_english',
    langCode: 'en',
  },
  {
    lang: 'German',
    langSelect: 'language_select_german',
    langCode: 'de',
  },
  {
    lang: 'Spanish',
    langSelect: 'language_select_spanish',
    langCode: 'es',
  },
  {
    lang: 'French',
    langSelect: 'language_select_french',
    langCode: 'fr',
  },
  {
    lang: 'Italian',
    langSelect: 'language_select_italian',
    langCode: 'it',
  },
  {
    lang: 'Chinese',
    langSelect: 'language_select_chinese',
    langCode: 'zh',
  },
]

export const languageMap = new Map(languages.map(({ langCode, lang }) => [langCode, lang]))
export const languageNames = languages.map(({ lang }) => lang)
export const languageCodes = languages.map(({ langCode }) => langCode)

const resources = {
  en: {
    translation: english,
  },
  de: {
    translation: german,
  },
  it: {
    translation: italian,
  },
  es: {
    translation: spanish,
  },
  fr: {
    translation: french,
  },
  zh: {
    translation: chinese,
  },
}

const remoteBackendOptions = {
  loadPath: `${process.env.REACT_APP_TRANSLATION_API_URI}/translations/{{lng}}/{{ns}}`,
  customHeaders: {
    'x-api-key': process.env.REACT_APP_TRANSLATION_API_KEY,
  },
  crossDomain: true,
}

const i18nTestConfig =
  process.env.NODE_ENV === 'test'
    ? { resources: resources }
    : {
        backend: {
          backends: [LocalBackend, HttpBackend],
          backendOptions: [{ resources: resources }, remoteBackendOptions],
        },
      }

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    ns: [
      'translation',
      'statistics-component-library',
      'recommendations',
      'component-name',
      'component-type',
      'measurement-type',
      'event-type',
      'event',
    ],
    defaultNS: 'translation',
    supportedLngs: languageCodes,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'language',
    },
    ...i18nTestConfig,
  })


const namespace = 'event'
const lng = 'en'


console.log('risk', i18n.getResource(lng, namespace, 'risk'))
console.log('state', i18n.getResource(lng, namespace, 'state'))

i18n.loadNamespaces(namespace, () => {

  i18n.addResource(lng, namespace, 'risk.0', 'Unspecified')
  i18n.addResource(lng, namespace, 'risk.1', 'Event')
  i18n.addResource(lng, namespace, 'risk.2', 'Faults')
  i18n.addResource(lng, namespace, 'risk.3', 'Warnings')

  console.log('risk1', i18n.getResource(lng, namespace, 'risk'))
  console.log('state1', i18n.getResource(lng, namespace, 'state'))
})


export default i18n
