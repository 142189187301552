import React from 'react'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

import { Feature } from '../api/types'
import swfTheme, { loadTheme as loadSwfTheme } from './swf'
import whiteLabelTheme, { loadTheme as loadWhiteLabelTheme } from './whiteLabel'
import rmTheme, { loadTheme as loadRmTheme } from './rm'
import verlindeTheme, { loadTheme as loadVerlindeTheme } from './verlinde'
import donatiTheme, { loadTheme as loadDonatiTheme } from './donati'
import demagTheme, { loadTheme as loadDemagTheme } from './demag'

type TileProps = {
  color?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  descriptionColor?: string
  descriptionBackgroundColor?: string
  demoBackgroundColor?: string // Demo ribbon color
  titleColor?: string
  titleHoverColor?: string
  iconColor?: string // Overrides color, applies to all icons in a tile.
  shadow?: boolean // Unused
  showLinkIcon?: boolean // Unused
}

type TileIconProps = {
  icon?: IconDefinition // FA icons only
  size?: string // FA icons only
  width?: string // Custom icons only
  style?: {
    fontSize?: string
  }
}

type MenuItemIconProps = {
  icon?: IconDefinition // FA icons only
  size?: string // FA icons only
  width?: string // Custom icons only
}

type ButtonStyles = {
  color?: string
  textColor?: string
  hover?: string
  disabled?: boolean
}

export type ModuleName =
  | 'dashboard'
  | 'contact'
  | 'documents'
  | 'documentsAndManuals'
  | 'myPartnersAndEquipment'
  | 'store'
  | 'deliveryTracker'
  | 'mediaLibrary'
  | 'statusControl'
  | 'trainingCalendar'
  | 'warranty'
  | 'leadTimes'
  | 'leadTimeDemo'
  | 'businessDashboard'
  | 'troubleshooting'
  | 'marketingBank'
  | 'chainSystem'
  | 'craneMaster'
  | 'eLearning'
  | 'serviceDocuments'
  | 'healthApp'
  | 'verlindeCom'
  | 'priceList'
  | 'generalPriceList'
  | 'stagemakerPriceList'
  | 'training'
  | 'mySWF'
  | 'modalMenu'
  | 'news'
  | 'stagemaker'
  | 'leonardoProductConfigurator'
  | 'leonardoCraneSetConfigurator'
  | 'electricChainHoists'
  | 'electricWireRopeHoists'
  | 'jibCranes'
  | 'driveUnits'
  | 'endCarriages'
  | 'channelProfiles'
  | 'craneSets'
  | 'suspendedMonogirderBridgeCranes'
  | 'assetFleet'
  | 'remoteDataDemo'
  | 'monitoredAsset'
  | 'hotjarDecrypt'
  | 'activationFlow'
  | 'termsOfSale'
  | 'orderDocumentation'

// Required keys should be required. Default module should extend this TODO
export interface Module {
  name: ModuleName
  to: string
  title: string // Default module title.
  tileTitle?: string // Override title on the dashboard.
  pageTitle?: string // Override title in the page heading.
  menuItemTitle?: string // Override title in the side menu.
  description?: string
  tooltip?: string
  secondary?: boolean // FIXME: Move to tile props?
  disabled?: boolean // This will override the permission check.
  external?: boolean
  showExternalIcon?: boolean // Force show/hide the external icon.
  demo?: boolean
  unlocked?: boolean
  upperCaseTitle?: boolean // R&M only for now. FIXME Pages should read from here before converting to upper case.
  hideDescription?: boolean
  showOnDashboard?: boolean
  showInMenu?: boolean
  permissionCheckArgs?: [Feature['name'], Feature['grants']]
  modalContent?: JSX.Element
  tileProps?: TileProps
  IconComponent: React.FC<any> // FA or custom icon.
  tileIconProps?: TileIconProps
  menuItemIconProps?: MenuItemIconProps
}

export type BrandName = 'swf' | 'verlinde' | 'donati' | 'demag' | 'rm' | 'whiteLabel'

export type Theme = {
  brand: BrandName
  portalName?: string
  privacyPolicyLink?: string
  cookiePolicyLink?: string
  companyLink?: string
  siteTermsLink: string
  fontSize?: string
  fontFamily?: string
  storeUrl?: string // FIXME: Demag specific: this should be configurable in the store module. Still used in routes and breadcrumb.
  footerCopyrightId?: string
  footerColor?: string
  footerBackgroundColor?: string
  footerBackground?: {
    desktopImage?: string
    tabletImage?: string
    mobileImage?: string
  }
  pageHeaderColor?: string
  headerColor?: string
  headerBorder?: boolean
  headerBackgroundColor?: string
  primary?: string // Primary color? FIXME: rename
  headerLogo: string
  altHeaderLogo?: string
  menuLogo: string
  footerLogo: string
  headerLogoSize: string
  altHeaderLogoSize?: string
  menuLogoSize: string
  footerLogoSize: string
  linkColor: string
  linkHoverColor: string
  dashboardBanner?: {
    desktopImage?: string
    tabletImage?: string
    mobileImage?: string
    title?: string
    subtitle?: string
    titleColor?: string
    borderColor?: string
  }
  healthApp?: {
    image: string
    qrAppStore: string
    qrGooglePlay: string
    appStoreUrl: string
    googlePlayUrl: string
    moreInfoUrl: string
  }
  button: {
    primary: ButtonStyles
    secondary?: ButtonStyles
  } & ButtonStyles
  listItem: {
    titleColor?: string
    descriptionColor?: string
  }
  header: {
    color?: string
    backgroundColor?: string
  }
  menuLink: {
    color?: string
    hoverColor?: string
    hoverBackgroundColor?: string
    fontWeight?: string
  },
  registerUri?: {
    storeDomain: string
    storeAbbr: string
  },
  newsModal?: {
    tagBackground: string
  },
  modules: Module[] // Modules enabled for the brand (dashboard tiles, side menu items...)
}

const swfThemeObj = {
  themeConfig: swfTheme,
  loadTheme: loadSwfTheme,
}

const themes = {
  swfkrantechnik: swfThemeObj,
  donati: {
    themeConfig: donatiTheme,
    loadTheme: loadDonatiTheme,
  },
  demag: {
    themeConfig: demagTheme,
    loadTheme: loadDemagTheme,
  },
  verlinde: {
    themeConfig: verlindeTheme,
    loadTheme: loadVerlindeTheme,
  },
  rm: {
    themeConfig: rmTheme,
    loadTheme: loadRmTheme,
  },
  localhost: swfThemeObj,
  whiteLabel: {
    themeConfig: whiteLabelTheme,
    loadTheme: loadWhiteLabelTheme,
  },
}

export type ThemeName = Exclude<keyof typeof themes, 'localhost'>

export const supportedThemes = Object.keys(themes).filter((t) => t !== 'localhost')

export default themes
