import { BackendModule, Services, ReadCallback, CallbackError } from 'i18next'

interface LocalBackendOptions {
  resources: {
    [language: string]: {
      [namespace: string]: object
    }
  }
}

class LocalBackend implements BackendModule<LocalBackendOptions> {
  services!: Services
  options!: LocalBackendOptions
  type: 'backend' = 'backend'

  constructor(services: Services, options: LocalBackendOptions = { resources: {} }, allOptions = {}) {
    this.init(services, options, allOptions)
  }

  init(services: Services, options: LocalBackendOptions = { resources: {} }, allOptions = {}) {
    this.services = services
    this.options = options
  }

  read(language: string, namespace: string, callback: ReadCallback) {
    try {
      const resources = this.options.resources
      if (resources && resources[language] && resources[language][namespace]) {
        return callback(null, resources[language][namespace])
      }
      callback(new Error(`Local resource for ${language}/${namespace} not found.`), false)
    } catch (err) {
      callback(err as CallbackError, false)
    }
  }
  
  create(languages: string[], namespace: string, key: string, fallbackValue: string) {
  }
}

export default LocalBackend
