import React from 'react'
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider } from '@apollo/client'

import { useToken } from '../TokenContext'

// const buildEnv = process.env.STAGE || 'dev'

const routerKeys = {
  dev: '1739E25A-6BBF-48F7-BE54-4938DD6CCAD9',
  qa: 'D0CC1FF8-E0CD-4333-9C7B-188C60D46364',
  pilot: 'AD30E879-83A3-4423-8D90-D2C92AC7D69F',
  prod: 'EC4BAD51-F77F-4BCE-8E39-D6B4DED40E0F',
}

const cache = new InMemoryCache({
  typePolicies: {
    TrendPeriod: {
      keyFields: false,
    },
    Facet: {
      keyFields: false,
    },
    Facets: {
      keyFields: false,
    },
    EventIdFacet: {
      keyFields: false,
    },
    EventPeriod: {
      keyFields: false,
    },
    MostFrequentEvent: {
      keyFields: false,
    },
  },
})

export const ApolloClientProvider: React.FC = ({ children }) => {
  const { authToken } = useToken()


  const client = new ApolloClient({
    connectToDevTools: true,
    cache: cache,
    link: new HttpLink({
      uri: process.env.REACT_APP_ASSET_FLEET_API,
      fetchOptions: {
        mode: 'cors',
      },
      headers: {
        Authorization: authToken ? `Bearer ${authToken}` : '',
        'Content-Type': 'application/json',
        'x-router-key': routerKeys['pilot'],
      },
    }),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
